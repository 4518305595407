:not(input) {
    user-select: none;
    cursor: default;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

td {
    text-align: center;
}

input {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
}
